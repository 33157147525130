import { Outlet, useLocation, useParams } from 'react-router-dom';

import Logo from 'assett/icons/logo.svg';
import { setDialogOpen } from 'feature/hooks/dialogSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { useLogout } from 'hooks/login.hook';
import { useResetPage } from 'hooks/utils.hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Box, List, ListItem, ListItemButton, ListItemIcon } from 'styles';
import CardMedia from 'styles/card/CardMedia';
import Container from 'styles/container/Container';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { VERSION } from 'types/dialogTypes';
import { TRANSLATION_KEYS } from 'utils/constants';
import { Logout } from 'utils/links';
import { FooterDesktop } from '../FooterDesktop/FooterDesktop';
import Breadcrumbs from './Breadcrumbs.component';

export default function SharedLayout() {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch();
	const location = useLocation();

	const { isDesktop } = useDevice();
	const confirmDialog = useDialogConfirm();

	const handleLogout = useLogout();

	const { id } = useParams();
	const { claimData } = useSelector((store: RootState) => store.claim);

	const paths = location.pathname.split('/').map(e => {
		if (decodeURI(e) === id) {
			if (!!id && !location.pathname.includes('check-and-submit')) {
				return `${tClientLabels('claim.breadcrumb')} #${claimData.externalClaimId}`;
			}

			return id;
		}

		return tClientLabels(`${e}.pageTitle`);
	});

	useResetPage();

	return (
		<Container sx={{ height: '100vh', overflow: 'hidden' }}>
			{/* <Sidebar /> */}
			<Stack width={'100%'}>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Stack sx={{ mt: { sm: 6.25, lg: 8.75 } }}>
						<Typography variant="title2">
							{location.pathname
								.split('/')
								.map(
									(s: string, index: number) =>
										index === paths.length - 1 && paths[index],
								)}
						</Typography>

						<Breadcrumbs claimId={claimData.externalClaimId} />
					</Stack>
					<Stack alignItems={'flex-end'} sx={{ mt: { sm: 6.25, lg: 8.75 } }}>
						<Box width="48px" height="60px" sx={{ cursor: 'pointer' }}>
							<CardMedia
								component="img"
								image={Logo}
								onClick={() => {
									confirmDialog.open(VERSION);
									dispatch(setDialogOpen(true));
								}}
							/>
						</Box>
					</Stack>
				</Stack>
				<Stack
					sx={{
						height: `calc(100vh - ${isDesktop ? '240px' : '204px'})`,
						width: `calc(100vw - ${isDesktop ? 'auto' : '180px'})`,
					}}
				>
					<Outlet />
				</Stack>
				<Stack direction="row">
					<List>
						{Logout(tClientLabels).map(action => (
							<ListItem
								key={action.id}
								disablePadding
								sx={{ display: 'block' }}
								onClick={handleLogout}
							>
								<ListItemButton>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: 3,
											justifyContent: 'center',
											px: !isDesktop ? 0.5 : 0,
										}}
									>
										{action.icon}
									</ListItemIcon>
								</ListItemButton>
							</ListItem>
						))}
					</List>
					<FooterDesktop />
				</Stack>
			</Stack>
		</Container>
	);
}
